export const facetConfig = {
  single: [
    'Status',
    'DocumentType',
    'ReferenceDetailPositionTitle',
    'ReferenceDetailType',
    'ReferenceDetailLevel',
    'ReferenceDetailBearingStructure',
    'ReferenceDetailFrontFacadeType',
    'ReferenceDetailSideFacadeType',
    'ReferenceDetailFacadeType',
    'ReferenceDetailBearingStructureType',
    'ReferenceDetailFloorType',
  ],
  multi: ['Category', 'SubCategory', 'Publisher', 'Layer'],
};

export const facetDefaults = {
  single: [
    {
      name: 'Status',
      value: 'Actueel',
    },
  ],
  multi: [],
  view: 'list',
};
